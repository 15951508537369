<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Pincode"
              >
                <label>Pincode</label>
                <label style="color: red !important">*</label>

                <b-form-input
                  v-model="Pincode"
                  maxlength="6"
                  @input="validatePincodeClick($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Pincode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="pincodeValidate == false" class="text-danger"
                  >Enter Valid Pincode</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Area"
              >
                <b-form-input
                  v-model="PostOfficeName"
                  type="text"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <b-form-input
                  v-model="City"
                  type="text"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter City"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>District</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="District"
              >
                <b-form-input
                  v-model="District"
                  type="text"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter District"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="State"
              >
                <b-form-input
                  v-model="State"
                  type="text"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter State"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      State: "",
      City: "",
      Pincode: "",
      PostOfficeName: "",
      District: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      pincodeValidate: true,
      flag: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Area") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/area");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/area");
          }
        }
      });
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    validatePincodeClick(e) {
      const regex = new RegExp(/^[1-9]{1}[0-9]{2}[0-9]{3}$/u);
      if (regex.test(this.Pincode) == true) {
        this.pincodeValidate = true;
      } else {
        this.pincodeValidate = false;
      }
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getpincodesById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      this.PostOfficeName = data.PostOfficeName;
      this.City = data.City;
      this.Pincode = data.Pincode;
      this.State = data.State;
      this.District = data.District;
    },
    submitForm(e) {
      const data = {
        State: this.State,
        City: this.City,
        Pincode: this.Pincode,
        PostOfficeName: this.PostOfficeName,
        District: this.District,
      };
      // va: this =,this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false || this.pincodeValidate == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.pincodeValidate == true) {
          this.flag = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/pincodes/${this.$route.params.id}`
              : `${baseApi}/pincodes`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/master/crm/area");
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/master/crm/area");
    },
  },
};
</script>
